body {
  background-color: rgb(240, 242, 245);
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.login-wrapper form {
  width: 300px;
  padding: 20px;
  background-color: whitesmoke;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.login-form-button {
  text-align: center;
}