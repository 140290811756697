.galleryContainer {
    width: 100%;
    height: calc(100vh - 139px);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.imageItem {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.imageItem .imagePlaceholder {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
}

.imageItem .imagePlaceholderActions {
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.imageItem img {
    width: 100%;
    height: auto;
}

.imageRegularItem:hover {
    transform: scale(1.1);
}

.imageItem:hover .imagePlaceholderContainer {
    display: block
}

.imagePlaceholderContainer {
    display: none;
}

.galleryGrid {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(2, auto); */
    padding-top: 10px;
    min-height: 400px;
    box-sizing: border-box;
}

.annotationTags {
    position: absolute;
    padding: 0 5px;
}

.annotationTags span {
    margin: 2px;
}