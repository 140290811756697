.galleryFilter {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #f0faf2;
}

.galleryFilterItem {
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    background-color: #CBD8CD;
}

.galleryFilterItemActive {
    color: #383333;
    background-color: #919d86;
}

.galleryFilterItem:hover {
    color: #272525;
    background-color: #919d86;
}

.filterCntBadge {
    background-color: #FBFADA;
    padding: 4px;
    border-radius: 8px;
    color: #000;
    font-size: 10px;
    font-weight: bold;
}