.galleryContainer {
    width: 100%;
    height: calc(100vh - 74px);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.imageItem {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageStatePlaceholder {
    top: 40px;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid white;
    border-radius: 50%;
}

.imageItemActive .imagePlaceholderContainer {
    display: block
}

.imageItem:hover .imagePlaceholderContainer {
    display: block
}

.imagePlaceholderContainer {
    display: none;
}

.imageItem .imagePlaceholder {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
}

.imageItem .imagePlaceholderActions {
    width: 100%;
    height: 100%;
    padding-top: 80px;
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
}

.imagePlaceholderActions button {
    width: 60%;
    margin: 5px 0;
}

/* .imageItem img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 336px;
} */

.imageRegularItem:hover {
    transform: scale(1.1);
}

.galleryGrid {
    display: grid;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    min-height: 400px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
}

.customLabelContainer {
    display: block;
}

.availableTagList {
    list-style: none;
    padding: 10px 0;
}

.availableTagList>li {
    display: inline;
    padding: 4px 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: rgb(250, 173, 20);
    border-radius: 8px;
}