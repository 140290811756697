.galleryContainer {
    width: 100%;
    height: calc(100vh - 40px);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.imageItem img {
    border-radius: 4px;
}

.galleryList {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.imageItem {
    border-radius: 8px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    padding: 10px;
    margin: 10px 0;
}
.classificationDistribution{
    background-color: rgba(0, 0, 0, 0.02);
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    color: #262626;
}
