.imagesContainer {
    background-color: '#ffffff';
    padding: '5px';
    border-radius: '8px',
}

.toolBarPannel {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.editModeBlock {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    padding: 5px;
    box-sizing: border-box;
}

.annotationStatsBar span {
    margin-bottom: 4px;
}