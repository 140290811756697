.spaceAlignLeftBlock h2 {
    margin: 0;
}

.spaceAlignContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.spaceAlignRighBlock {
    padding: 4px;
}